import { useState, useRef, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Chip,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import ReactMarkdown from 'react-markdown';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import User1 from 'assets/images/users/user-round.svg';
import useLogin from 'hooks/useLogin';
import { API } from 'utils/api';

// assets
import { IconLogout, IconSettings, IconUserScan,IconHome, IconBellRinging } from '@tabler/icons-react';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  // 使用Material-UI主题
  const theme = useTheme();
  // 获取导航函数
  const navigate = useNavigate();
  // 从Redux store中获取自定义设置
  const customization = useSelector((state) => state.customization);
  // 获取登出函数
  const { logout } = useLogin();

  // 控制下拉菜单的开关状态
  const [open, setOpen] = useState(false);
  // 控制公告对话框的开关状态
  const [openAnnouncement, setOpenAnnouncement] = useState(false);
  // 存储公告内容
  const [announcement, setAnnouncement] = useState('');
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  // 创建一个引用，用于下拉菜单的定位
  const anchorRef = useRef(null);
  // 处理登出操作
  const handleLogout = async () => {
    logout();
  };

  // 处理下拉菜单关闭
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  // 切换下拉菜单的开关状态
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // 存储上一次的open状态
  const prevOpen = useRef(open);
  // 使用useEffect来处理焦点
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  // 新增处理公告点击的函数
  const handleAnnouncementClick = async () => {
    try {
      const res = await API.get('/api/notice');
      if (res.data.success) {
        setAnnouncement(res.data.data);
        setOpenAnnouncement(true);
      }
    } catch (error) {
      console.error('获取公告失败:', error);
    }
  };

  // 处理公告对话框关闭
  const handleAnnouncementClose = () => {
    setOpenAnnouncement(false);
  };

  return (
    <>
      {/* 调整圆角的公告按钮 */}
      <Button
        variant="contained"
        startIcon={<IconBellRinging size="1rem" />}
        sx={{
          minWidth: 'auto',
          padding: '6px 16px',
          borderRadius: '10px', // 增加圆角值
          backgroundColor: theme.palette.grey[100],
          color: theme.palette.text.primary,
          boxShadow: 'none',
          overflow: 'hidden', // 确保内容不会超出边界
          '&:hover': {
            backgroundColor: theme.palette.grey[200],
            boxShadow: 'none',
          },
          marginRight: '12px',
        }}
        onClick={handleAnnouncementClick}
      >
        公告
      </Button>

      <Chip
        sx={{
          height: '40px',
          alignItems: 'center',
          borderRadius: '20px',
          transition: 'all .2s ease-in-out',
          backgroundColor: theme.palette.background.paper,
          '&[aria-controls="menu-list-grow"], &:hover': {
            backgroundColor: theme.palette.primary.light,
            '& svg': {
              stroke: theme.palette.primary.main
            }
          },
          '& .MuiChip-label': {
            lineHeight: 0
          }
        }}
        icon={
          <Avatar
            src={User1}
            sx={{
              ...theme.typography.mediumAvatar,
              margin: '8px 0 8px 8px !important',
              cursor: 'pointer'
            }}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.text.primary} />}
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <List
                    component="nav"
                    sx={{
                      width: '100%',
                      maxWidth: 350,
                      minWidth: 150,
                      backgroundColor: theme.palette.background.paper,
                      borderRadius: '10px',
                      [theme.breakpoints.down('md')]: {
                        minWidth: '100%'
                      },
                      '& .MuiListItemButton-root': {
                        mt: 0.5
                      }
                    }}
                  >
                    <ListItemButton sx={{ borderRadius: `${customization.borderRadius}px` }} onClick={() => navigate('/profile')}>
                      <ListItemIcon>
                        <IconUserScan stroke={1.5} size="1.3rem" />
                      </ListItemIcon>
                      <ListItemText primary={<Typography variant="body2">设置</Typography>} />
                    </ListItemButton>
                    <ListItemButton sx={{ borderRadius: `${customization.borderRadius}px` }} onClick={() => navigate('/home')}>
                      <ListItemIcon>
                        <IconHome stroke={1.5} size="1.3rem" />
                      </ListItemIcon>
                      <ListItemText primary={<Typography variant="body2">首页</Typography>} />
                    </ListItemButton>

                    <ListItemButton sx={{ borderRadius: `${customization.borderRadius}px` }} onClick={handleLogout}>
                      <ListItemIcon>
                        <IconLogout stroke={1.5} size="1.3rem" />
                      </ListItemIcon>
                      <ListItemText primary={<Typography variant="body2">退出</Typography>} />
                    </ListItemButton>
                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>

      {/* 公告对话框 */}
      <Dialog
        open={openAnnouncement}
        onClose={handleAnnouncementClose}
        aria-labelledby="announcement-dialog-title"
        aria-describedby="announcement-dialog-description"
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: '12px',
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
            overflow: 'hidden'
          }
        }}
      >
        <DialogTitle id="announcement-dialog-title" sx={{ 
          padding: '20px 24px',
          fontSize: '1.2rem',
          fontWeight: 600,
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.background.default
        }}>
          系统公告
        </DialogTitle>
        <DialogContent sx={{ 
          padding: '24px',
          '& p': { marginBottom: '16px', lineHeight: 1.6 },
          '& h1, & h2, & h3': { 
            marginTop: '24px',
            marginBottom: '16px',
            fontWeight: 600,
            color: theme.palette.text.primary
          },
          '& > *': {
            wordWrap: 'break-word',
            overflowWrap: 'break-word'
          },
          maxHeight: '60vh', // 添加最大高度
          overflowY: 'auto' // 添加垂直滚动条
        }}>
          <ReactMarkdown>{announcement}</ReactMarkdown>
        </DialogContent>
        <DialogActions sx={{ 
          padding: '16px 24px', 
          backgroundColor: theme.palette.background.default,
          justifyContent: 'flex-end'
        }}>
          <Button 
            onClick={handleAnnouncementClose} 
            variant="contained" 
            color="primary"
            sx={{ 
              textTransform: 'none',
              fontWeight: 500,
              borderRadius: '20px',
              padding: '6px 20px'
            }}
          >
            我知道了
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfileSection;
